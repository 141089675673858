<!-- <template>
    <div>
        <div>
            <el-form ref="purchasingRequisitionRef1" size="mini" :rules="purchasingRequisitionRules"
                :model="purchasingRequisitionForm" :disabled="is_Readonly">
                <el-form-item label="日期">
                    <el-date-picker type="date" value-format="yyyy-MM-dd" placeholder="下单日期"
                        v-model="purchasingRequisitionForm.filing_date" style="width: 100%;" :picker-options="pickerOptions"
                        clearable></el-date-picker>
                </el-form-item>
                <el-form-item label="职员">
                    <OrgSelect @input="onCreator" :maxNum="1" :tab="'user'" :value="purchasingRequisitionForm.creator_arr"
                        :isCheckbox="true" buttonType="button" :title="'申请人'">
                    </OrgSelect>
                    <el-input style="display: none;" v-model="purchasingRequisitionForm.creator_arr"
                        placeholder="请输入内容"></el-input>
                </el-form-item>
                <el-form-item label="接收仓库">
                    <el-input v-model="purchasingRequisitionForm.warehouseValue" placeholder="请输入内容" clearable>
                        <el-button size="mini" slot="prepend" icon="el-icon-search" @click="isWarehouse = true"></el-button>
                    </el-input>
                </el-form-item>
                <el-form-item label="货币类型">
                    <el-select style="width: 100%;" v-model="purchasingRequisitionForm.currency" placeholder="请选择"
                        clearable>
                        <el-option v-for="item in currencyOptions" :label="item.label" :value="item.value"
                            :key="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="是否含税">
                    <el-select @change="onTax" style="width: 100%;" v-model="purchasingRequisitionForm.is_tax" filterable
                        allow-create default-first-option placeholder="请选择" clearable>
                        <el-option v-for="item in isTaxOption" :key="item.value" :label="item.label" :value="item.value"
                            clearable>
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="交付日期">
                    <el-date-picker type="date" value-format="yyyy-MM-dd" placeholder="交付日期"
                        v-model="purchasingRequisitionForm.required_date" style="width: 100%;"
                        :picker-options="pickerOptions" clearable></el-date-picker>
                </el-form-item>
                <el-form-item label="交易法人">
                    <el-select style="width: 100%;" v-model="purchasingRequisitionForm.legal_entity" placeholder="请选择"
                        clearable>
                        <el-option v-for="item in legalEntityOption" :label="item.label" :value="item.value"
                            :key="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="费用代码">
                    <template>
                        <el-select style="width: 100%;" v-model="purchasingRequisitionForm.charge_code" clearable
                            placeholder="请选择">
                            <el-option v-for="item in chargeCodeOption" :label="item.code_name" :value="item"
                                :key="item.value">
                            </el-option>
                        </el-select>
                    </template>
                </el-form-item>
                <el-form-item label="发文单位">
                    <OrgSelect @input="onDispatchDept" :maxNum="1" :tab="'dep'"
                        :value="purchasingRequisitionForm.dispatch_arr" :isCheckbox="true" buttonType="button"
                        :title="'部门'">
                    </OrgSelect>
                    <el-input style="display: none;" v-model="purchasingRequisitionForm.dispatch_arr"
                        placeholder="请输入内容"></el-input>
                </el-form-item>
                <el-form-item label="受文单位">
                    <OrgSelect @input="onDept" :maxNum="1" :tab="'dep'"
                        :value="purchasingRequisitionForm.incoming_dispatches_arr" :isCheckbox="true" buttonType="button"
                        :title="'部门'">
                    </OrgSelect>
                    <el-input style="display: none;" v-model="purchasingRequisitionForm.incoming_dispatches_arr"
                        placeholder="请输入内容"></el-input>
                </el-form-item>
                <el-form-item label="发起原因">
                    <el-input type="textarea" :autosize="{ minRows: 3, maxRows: 16 }"
                        v-model="purchasingRequisitionForm.reason"></el-input>
                </el-form-item>
                <el-form-item label="备注">
                    <el-input type="textarea" :autosize="{ minRows: 3, maxRows: 16 }"
                        v-model="purchasingRequisitionForm.remark"></el-input>
                </el-form-item>

            </el-form>
        </div>
        <div>
            <el-table v-if="!$store.state.isphone" :data="purchasingRequisitionForm.details" ref="table_ref"
                min-width="1300px" size="mini" border :header-cell-style="{ 'text-align': 'center' }"
                :cell-style="{ textAlign: 'center' }">
                <el-table-column type="index" label="序号" width="50px" fixed="left">
                </el-table-column>
                <el-table-column prop="product_code" label="品目编码">
                    <template slot-scope="scope">
                        <el-input :disabled="is_Readonly" size="mini" v-model="scope.row.product_code" placeholder="品目编码"
                            @keyup.enter.native="onProductCode(scope.$index)" @blur.stop="onProductCode(scope.$index)"
                            clearable>
                            <el-button size="mini" slot="prepend" :disabled="is_Readonly" icon="el-icon-search pointer"
                                @click="onProductCode(scope.$index)"></el-button>
                        </el-input>
                    </template>
                </el-table-column>
                <el-table-column prop="product_name" label="品目名称">
                </el-table-column>
                <el-table-column prop="specifications" label="规格">
                </el-table-column>
                <el-table-column prop="unit" label="单位" width="60px">
                </el-table-column>
                <el-table-column prop="quantity" label="数量">
                    <template slot-scope="scope">
                        <el-input-number v-model="scope.row.quantity" controls-position="right"
                            size="mini"></el-input-number>
                    </template>
                </el-table-column>
                <el-table-column prop="unit_price" label="单价">
                    <template slot-scope="scope">
                        <el-input-number v-model="scope.row.unit_price" controls-position="right"
                            size="mini"></el-input-number>
                    </template>
                </el-table-column>
                <el-table-column label="成交金额">
                    <template slot-scope="scope">
                        <div>
                            {{ Thousands(accMul(scope.row.quantity, scope.row.unit_price, scope.$index)) }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="abstract" label="摘要">
                    <template slot-scope="scope">
                        <el-input v-model="scope.row.abstract" size="mini" :disabled="is_Readonly" placeholder="请输入内容"
                            clearable></el-input>
                    </template>
                </el-table-column>
                <el-table-column label="操作" fixed="right" width="120px" v-if="!is_Readonly">
                    <template slot-scope="scope">
                        <div>
                            <el-button type="primary" size="mini" icon="el-icon-plus pointer"
                                @click="onPushDetails(scope.$index)"></el-button>
                            <el-button type="danger" size="mini" icon="el-icon-delete pointer"
                                @click="onDeleteDetails(scope.$index)"></el-button>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
            <div v-else>
                <div v-if="is_Readonly">
                    <template v-for="(item, i) in purchasingRequisitionForm.details">
                        <div :key="i"
                            style="padding: 10px;background-color: #f8ffed;margin-bottom: 10px;border-radius: 12px;">
                            <div
                                style=" min-width: 300px;font-weight: 600;font-size: 24px;color: rgb(143, 195, 31);padding: 5px;">
                                {{ item.product_code }}
                            </div>
                            <div style="font-weight: 600; font-size: 16px; padding: 5px">
                                {{ item.product_title }}
                            </div>
                            <div class="card_item">
                                <div>量：</div>
                                <div>{{ Thousands(item.quantity) }}</div>
                            </div>
                            <div class="card_item">
                                <div>价：</div>
                                <div>
                                    {{ Thousands(item.unit_price) }}
                                </div>
                            </div>
                            <div class="card_item">
                                <div>总：</div>
                                <div>
                                    {{ Thousands(accMul(item.quantity, item.unit_price, i)) }}
                                </div>
                            </div>
                            <div class="card_item">
                                <div>摘：</div>
                                <div>{{ item.abstract }}</div>
                            </div>
                        </div>
                    </template>
                </div>
                <div v-else style="border: 1px dashed #8fc31f; padding: 10px; border-radius: 12px">
                    <template v-for="(item, i) in purchasingRequisitionForm.details">
                        <div :key="i"
                            style="border: 1px solid #dcdfe6;padding: 10px;margin-bottom: 10px;border-radius: 12px;">
                            <div style="display: flex;justify-content: space-between;align-items: center;">
                                <p>产品{{ i + 1 }}</p>
                                <i @click="onDeleteDetails(i)" class="el-icon-close pointer"></i>
                            </div>
                            <el-form :model="item">
                                <el-form-item label="品目编码" prop="product_code">
                                    <el-input :disabled="is_Readonly" size="mini" v-model="item.product_code"
                                        placeholder="品目编码" @keyup.enter.native="onProductCode(i)"
                                        @blur.stop="onProductCode(i)" clearable>
                                        <el-button size="mini" slot="prepend" :disabled="is_Readonly"
                                            icon="el-icon-search pointer" @click="onProductCode(i)"></el-button>
                                    </el-input>
                                </el-form-item>
                                <el-form-item label="品目名称">
                                    <el-input v-model="item.product_name" size="mini" :disabled="is_Readonly"
                                        placeholder="请输入内容" clearable></el-input>
                                </el-form-item>
                                <el-form-item label="数量">
                                    <el-input v-model="item.quantity" size="mini" :disabled="is_Readonly"
                                        placeholder="请输入内容" clearable></el-input>
                                </el-form-item>
                                <el-form-item label="单价">
                                    <el-input v-model="item.unit_price" size="mini" :disabled="is_Readonly"
                                        placeholder="请输入内容" clearable></el-input>
                                </el-form-item>
                                <el-form-item label="单位">
                                    <el-input v-model="item.unit" size="mini" :disabled="is_Readonly" placeholder="请输入内容"
                                        clearable></el-input>
                                </el-form-item>
                                <el-form-item label="成交金额">
                                    <el-input v-model="item.total_actual_amount" size="mini" :disabled="is_Readonly"
                                        placeholder="请输入内容" clearable></el-input>
                                </el-form-item>
                                <el-form-item label="规格">
                                    <el-input v-model="item.specifications" size="mini" :disabled="is_Readonly"
                                        placeholder="请输入内容" clearable></el-input>
                                </el-form-item>
                                <el-form-item label="摘要">
                                    <el-input v-model="item.abstract" size="mini" type="textarea" :disabled="is_Readonly"
                                        placeholder="请输入内容" clearable></el-input>
                                </el-form-item>
                            </el-form>
                        </div>
                    </template>
                    <div>
                        <el-button style="width: 100%" :disabled="is_Readonly" @click="onPushDetails"
                            icon="el-icon-plus pointer" type="text">添加产品</el-button>
                    </div>
                </div>
            </div>
        </div>
        <inquireDialog v-if="isWarehouse" :isVisible="isWarehouse" :obj="warehouseObj" :title="'仓库'" :isChoose="true"
            :searchValue="purchasingRequisitionForm.warehouseValue" @onIsVisible="onWarehouseVisible"
            @onApplied="onWarehouseApplied">
        </inquireDialog>
        <inquireDialog v-if="isProduct" :isVisible="isProduct" :obj="productDialogObj" :title="'品目编码'" :isChoose="false"
            :searchValue="productCode" @onIsVisible="productVisible" @onApplied="productApplied">
        </inquireDialog>
    </div>
</template>
<script>
import OrgSelect from "@/components/OA/components/FormControls/OrgSelect/index.vue"
import inquireDialog from "@/components/ProcessFlow/CRM/Forms/components/Dialog.vue";
import Decimal from 'decimal.js';
export default {
    props: ["isPurchasingRequisition", "addPurchasingRequisitionForm", 'formTitle', "readonly", "dialogCustomerForm"],
    components: {
        inquireDialog, OrgSelect,
    },
    data() {
        return {
            is_Readonly: this.readonly == undefined ? false : this.readonly,
            purchasingRequisitionForm: {//采购单
                order_form_type: 1,
                filing_date: null,//制单日期
                creator: null,//职员
                creator_arr: null,//职员
                warehouseValue: null,//
                warehouse: null,//接收仓库
                legal_entity: null,//交易法人
                currency: null,//货币类型
                reason: null,//发起原因
                remark: null,//备注
                required_date: null,//交付日期
                charge_code: null,//费用代码
                is_tax: true,//是否含税
                dispatch: null,//发文单位
                dispatch_arr: null,//发文单位
                incoming_dispatches: null,//受文单位
                incoming_dispatches_arr: null,//受文单位
                details: [{//产品明细
                    product_code: null,//品目编码
                    product_name: null,//品目名称
                    product: null,//品目id
                    specifications: null,//规格
                    quantity: null, //产品数量
                    unit_price: null, //单价
                    unit: null, //入库单位
                    abstract: null,//摘要
                    special_offer_obj: null,
                    total_actual_amount: null,//总金额
                }]
            },
            legalEntityOption: null,
            currencyOptions: null,
            chargeCodeOption: null,
            //含税下拉框
            isTaxOption: [
                {
                    label: "是",
                    value: true,
                },
                {
                    label: "否",
                    value: false,
                },
            ],
            pickerOptions: {
                shortcuts: [
                    {
                        text: "今天",
                        onClick(picker) {
                            picker.$emit("pick", new Date());
                        },
                    },
                ],
            },
            purchasingRequisitionRules: {},

            /* 仓库 */
            isWarehouse: false,
            warehouseObj: {
                searchField: "keyword",
                url: "crm/outbound_product_warehouse/",
                params: {
                    per_page: 10,
                    page_number: 1,
                    user_id: localStorage.getItem("user_id"),
                    keyword: "",
                },
            },


            /* 品目编码*/
            //产品数组
            productArr: [],
            quotationTableIndex: null,
            productCode: null,
            isProduct: false,
            productDialogObj: {
                searchField: "search",
                url: "crm/special_offer/",
                params: {
                    per_page: 10,
                    page: 1,
                    model_name: "SpecialOfferTable",
                    sys_name: 3,
                    user: localStorage.getItem("user_id"),
                    search: "",
                    distinct: 1,
                    is_using: true,
                },
            },

        }
    },
    methods: {
        onDispatchDept(data) {
            console.log(data);
            this.purchasingRequisitionForm.dispatch_arr = data
            this.purchasingRequisitionForm.dispatch = data[0].id
        },
        onDept(data) {
            console.log(data);
            this.purchasingRequisitionForm.incoming_dispatches_arr = data
            this.purchasingRequisitionForm.incoming_dispatches = data[0].id
        },
        onCreator(data) {
            console.log(data);
            this.purchasingRequisitionForm.creator_arr = data
            this.purchasingRequisitionForm.creator = data[0].user_sys_id
        },
        onPushDetails() {
            this.purchasingRequisitionForm.details.push({
                product_code: null,
                product_name: null,
                product: null,
                specifications: null,
                quantity: null, //产品数量
                unit_price: null, //入库单价
                unit: null, //入库单价
                abstract: null,
                special_offer_obj: null,
                total_actual_amount: null,
            })
        },
        Thousands(num) {
            return (num * 1).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, "$1,");
        },
        accMul(arg1, arg2, index) {
            if (arg1 === null || arg2 === null || arg1 === '' || arg2 === '') {
                return "";
            } else {
                var m = 0,
                    s1 = arg1.toString(),
                    s2 = arg2.toString();
                try {
                    m += s1.split(".")[1].length;
                } catch (e) { }
                try {
                    m += s2.split(".")[1].length;
                } catch (e) { }
                let decimal1 = new Decimal(s1)
                let decimal2 = new Decimal(s2)
                let decimal3 = decimal1.times(decimal2)
                this.purchasingRequisitionForm.details[index].total_actual_amount = decimal3 * 1
                return decimal3 * 1
            }
        },
        onAddDetails(index) {
            console.log(index);
        },
        onDeleteDetails(index) {
            if (this.purchasingRequisitionForm.details.length > 1) {
                this.purchasingRequisitionForm.details.splice(index, 1)
            }
        },
        onOrdersChange(data, i) {
            this.productCode = data;
            this.quotationTableIndex = i;
            this.purchasingRequisitionForm.details[i].product_name = null;
            this.purchasingRequisitionForm.details[i].specifications = null;
            this.purchasingRequisitionForm.details[i].product = null;
        },
        //获取当前产名当前下标
        onProductCode(index) {
            this.quotationTableIndex = index;
            this.isProduct = true;
            console.log(index);
        },
        // 产品Dialog开关
        productVisible(data) {
            this.isProduct = data;
        },
        // 产品Dialog数据
        productApplied(data) {
            console.log(data);
            this.productArr = JSON.parse(JSON.stringify(data))
            const arr = [];
            this.productArr.forEach((item) => {
                let obj = {
                    product_code: item.item_code,
                    product_name: item.item_name,
                    product: item.product,
                    specifications: item.specifications,
                    special_offer_obj: item,
                    unit_price: null, //入库单价
                    unit: item.unit,
                    quantity: null, //产品数量
                    total_actual_amount: null, //产品数量
                    abstract: null,
                }
                arr.push(obj)
            })
            this.purchasingRequisitionForm.details.splice(this.quotationTableIndex, 1, ...arr)
        },
        onWarehouseVisible(val) {
            this.isWarehouse = val
        },
        onWarehouseApplied(val) {
            this.purchasingRequisitionForm.warehouse = val.id
            this.purchasingRequisitionForm.warehouseValue = val.warehouse_name
        },
        async getOption() {
            const res2 = await this.$http({
                method: "GET",
                url: "erp/charge_code/",
            })
            const res1 = await this.$http({
                method: "GET",
                url: "app02/label_control/label/?url=TradingLegalEntity/",
            })
            const res = await this.$http({
                method: "GET",
                url: "crm/outbound_currency_type/",
            });
            console.log(res2.data);
            this.chargeCodeOption = res2.data.table_body_data
            this.currencyOptions = res.data;
            this.legalEntityOption = res1.data
        },
        // 控制表单背景颜色
        getBackgroundColor() {
            if (this.purchasingRequisitionForm.is_tax == false) {
                if (document.querySelectorAll(".card_jsxPreview").length == 0) {
                    if (document.querySelectorAll(".dialog_jsxPreview").length == 0) {
                        document.querySelectorAll(
                            ".drawer_jsxPreview"
                        )[0].children[0].children[0].children[1].style.backgroundColor =
                            "#ff00000d";
                    } else {
                        document.querySelectorAll(
                            ".dialog_jsxPreview"
                        )[0].children[0].children[1].style.backgroundColor = "#ff00000d";
                    }
                } else {
                    document.querySelectorAll(
                        ".card_jsxPreview"
                    )[0].style.backgroundColor = "#ff00000d";
                }
            } else {
                if (document.querySelectorAll(".card_jsxPreview").length == 0) {
                    if (document.querySelectorAll(".dialog_jsxPreview").length == 0) {
                        document.querySelectorAll(
                            ".drawer_jsxPreview"
                        )[0].children[0].children[0].children[1].style.backgroundColor =
                            "#fff";
                    } else {
                        document.querySelectorAll(
                            ".dialog_jsxPreview"
                        )[0].children[0].children[1].style.backgroundColor = "#fff";
                    }
                } else {
                    document.querySelectorAll(
                        ".card_jsxPreview"
                    )[0].style.backgroundColor = "#fff";
                }
            }
        },
        onTax() {
            this.getBackgroundColor()
        }
    },
    watch: {
        readonly: {
            handler(val) {
                if (val == undefined) {
                    this.is_Readonly = false;
                } else {
                    this.is_Readonly = this.readonly;
                }
            },
            deep: true,
        },
        purchasingRequisitionForm: {
            handler(val) {
                if (this.isPurchasingRequisition == false) {
                    this.$emit("onAddOutboundOrder", this.purchasingRequisitionForm);
                }
            },
            deep: true,
        },
        addPurchasingRequisitionForm: {
            handler(val) {
                if (this.formTitle == "采购申请") {
                    if (val !== undefined) {
                        for (const key in val) {
                            this.purchasingRequisitionForm[key] = val[key]
                        }
                    }
                }
            },
            deep: true,
            immediate: true,
        },
        "purchasingRequisitionForm.details": {
            handler(val) {
                this.is_unit_price = false;
            },
            deep: true,
        }
    },
    mounted() {

    },
    created() {
        this.getOption()
    },
}
</script>
<style lang="less" scoped>
.card_item {
    display: flex;
    justify-content: flex-start;

}

.pointer {
    cursor: pointer;
}
</style> -->

<template>
    <div>
        <SettingForm ref="SettingAddProcurementPlan" :refs="'purchasingRequisitionRef1'"
            :isSettingLoading="isSettingLoading" :inline="true" :isFormBtn="false" :readonly="readonly"
            :settingForm="settingForm" @onAppliedForm="onAppliedForm">
        </SettingForm>
    </div>
</template>
<script>
import { EventBus } from '@/components/ProcessFlow/CRM/Forms/components/eventBus';
import SettingForm from "@/components/ProcessFlow/CRM/Forms/components/SettingForm.vue";
import Decimal from 'decimal.js';
export default {
    props: ["isPurchasingRequisition", "addPurchasingRequisitionForm", 'formTitle', "readonly", "dialogCustomerForm"],
    components: {
        SettingForm
    },
    data() {
        return {
            isSettingLoading: false,
            purchasingForm: {
            },
            settingForm: {
                form_root: '采购申请',
                classification: '采购申请',
                control_setting: [{
                    default_value: null,
                    options: [
                        {
                            label: null,
                            value: null,
                        },
                    ],
                    type: '文本输入框',
                    model_class: null,
                    url: null,
                    model_class_field: null,
                    model_class_value: null,
                    is_numeric: false,
                    particulars: [{
                        type: null,
                        url: null,
                        field: null,
                        acquire: null,
                        is_edit: true,
                        is_show: true,
                        label: null,
                        placeholder: null,
                    }],
                    permissions: {
                        edit: true,
                        show: true,
                        update: true,
                        require: false
                    },
                    visible_rule: {
                        role_limit: []
                    },
                    basic_settings: {
                        show_name: "field",
                        field: "field",
                        fields: "field",
                        placeholder: "field"
                    },
                    numeric_settings: {
                        max_digits: null,
                        decimal_places: null
                    },
                }]
            },
        }
    },
    methods: {
        onAppliedForm(val) {
            this.purchasingForm = val
        },
        async getSettingForm() {
            const res = await this.$http({
                method: "GET",
                url: "rbac/form_control_setting/",
                params: {
                    form_root: '采购申请',
                    classification: '采购申请',
                }
            })
            if (res.data[0] != null) {
                this.settingForm = JSON.parse(JSON.stringify(res.data[0]))
                let today = new Date();
                let date = today.getFullYear() + "-" + (today.getMonth() + 1 < 10 ? "0" + (today.getMonth() + 1) : today.getMonth() + 1) + "-" + (today.getDate() < 10 ? "0" + today.getDate() : today.getDate());
                this.settingForm.control_setting.forEach((item, i) => {
                    if (item.basic_settings.field == 'filing_date') {
                        item.default_value = date
                    }
                    if (item.basic_settings.field == 'is_tax') {
                        item.default_value = true
                    }
                    if (this.readonly) {
                        if (item.type == '下拉选择框(单选)' || item.type == '下拉选择框(多选)') {
                            this.getSelect(item)
                        }
                    }
                })
                this.isSettingLoading = true
            }
        },
        async getSelect(val) {
            if (val.url != null && val.url != '') {
                const res = await this.$http({
                    method: "GET",
                    url: val.url,
                })
                if (val.url == 'crm/customer_payment_days_verify/?customer_id=1') {
                    val.options = res.data.credit_rule_choice
                } else {
                    val.options = res.data
                }
            }
        },
        getSummaries(param) {
            const { columns, data } = param;
            const sums = [];
            columns.forEach((column, index) => {
                if (index === 0) {
                    sums[index] = "合计";
                    return;
                }
                const values = data.map((item) => Number(item[column.property]));
                if (column.property === "total_actual_amount") {
                    const sum = values.reduce((prev, curr) => {
                        const value = Number(curr);
                        if (!isNaN(value)) {
                            return prev + value;
                        } else {
                            return prev;
                        }
                    }, 0);
                    sums[index] = sum.toString().replace(/(\d)(?=(\d{3})+\.)/g, "$1,");
                } else {
                    sums[index] = '';
                }
            });
            return sums;
        },
    },
    watch: {
        settingForm: {
            handler(val) {
                EventBus.$emit('onIsEditForm', true);
            }
        },
        addPurchasingRequisitionForm: {
            handler(val) {
                if (this.formTitle == '采购申请') {
                    if (val !== undefined) {
                        for (const key in val) {
                            this.purchasingForm[key] = val[key]
                        }
                    }
                }
            },
            deep: true,
            immediate: true,
        },
        purchasingForm: {
            handler(val) {
                if (this.isPurchasingRequisition == false) {
                    this.$emit("onAddOutboundOrder", this.purchasingForm);
                }
            },
            deep: true,
        },
        "purchasingForm.details": {
            handler(val) {
                const isValid = (value) => value != null && value !== '';
                if (this.$store.state.isphone) {
                    val.forEach(item => {
                        if (isValid(item.quantity) && isValid(item.unit_price)) {
                            let s1 = item.unit_price.toString();
                            let s2 = item.quantity.toString();
                            let decimal1 = new Decimal(s1)
                            let decimal2 = new Decimal(s2)
                            let decimal3 = decimal1.times(decimal2)
                            item.total_actual_amount = decimal3 * 1
                        }
                    })
                } else {
                    if (this.$refs.SettingAddProcurementPlan.$refs.table_ref == undefined) {
                        return
                    }
                    const data = this.$refs.SettingAddProcurementPlan.$refs.table_ref[0].data;
                    const columns = this.$refs.SettingAddProcurementPlan.$refs.table_ref[0].columns;
                    data.forEach(row => {
                        if (isValid(row.unit_price) && isValid(row.quantity)) {
                            let s1 = row.unit_price.toString();
                            let s2 = row.quantity.toString();
                            let decimal1 = new Decimal(s1)
                            let decimal2 = new Decimal(s2)
                            let decimal3 = decimal1.times(decimal2)
                            row.total_actual_amount = decimal3 * 1
                        }
                    });
                    let arr = this.getSummaries({
                        columns: columns,
                        data: data
                    })
                    const footerWrapper = this.$refs.SettingAddProcurementPlan.$refs.table_ref[0].$refs.footerWrapper.querySelector('.has-gutter');
                    const cells = footerWrapper.querySelectorAll('.cell');
                    cells.forEach((item, i) => {
                        item.innerText = arr[i]
                    })
                }

            },
            deep: true,
        }
    },
    mounted() {

    },
    created() {
        this.getSettingForm()
    },
}
</script>