<template>
  <div>
    <SettingForm ref="ProcurementOrders" :refs="'ordersObjRef1'" :isSettingLoading="isSettingLoading" :inline="true"
      :isFormBtn="false" :readonly="readonly" :settingForm="settingForm" @onAppliedForm="onAppliedForm"
      @onModificationTable="onOrdersModificationTable" @onPreview="onOrdersPreview" @onSpecials="onOrdersSpecials"
      @onSpecialNumbers="onOrdersSpecialNumbers">
      <div slot="shortcut_button" style="margin-bottom: 10px;">
        <el-button type="primary" size="mini" @click="onSalesOrder">带出订单</el-button>
        <el-button size="mini" type="primary" @click="onSampleSubmission">带出送样单</el-button>
        <el-button type="primary" @click="onInventory">查看库存</el-button>
        <el-button type="primary" @click="onSalesOutInventory">带出库存</el-button>
      </div>
    </SettingForm>
    <el-dialog :modal="false" :append-to-body="false" :close-on-click-modal="false" :fullscreen="isFullscreen"
      :show-close="false" :visible.sync="isAddAddress" v-dialogDrags width="60%" style="overflow: auto">
      <div v-if="dialogFromObj != null" slot="title"
        style="display: flex;justify-content: space-between; align-items: center;">
        <div class="title">
          <span style="padding: 10px;">{{ dialogFromObj.label }}</span>
        </div>
        <div>
          <i v-if="isFullscreen" class="iconfont icon-zuidahua " @click.stop="onFullscreen('isFullscreen')"></i>
          <i v-else class="iconfont icon-chuangkouzuidahua" @click.stop="onFullscreen('isFullscreen')"></i>
          <i class="el-icon-close" @click="isAddAddress = false"></i>
        </div>
      </div>
      <span v-if="dialogFromObj != null">
        <Preview @getlist="getFormList" :dialogForm="dialogForm" :formal="true" :id="dialogFromObj.value"
          :specialDialog="specialDialog">
        </Preview>
      </span>
    </el-dialog>
    <inquireDialog v-if="isSpecial" :isVisible="isSpecial" :obj="specialDialogObj" :title="'特价'" :isChoose="true"
      :searchValue="specialValue" @onIsVisible="specialVisible" @onApplied="specialApplied">
    </inquireDialog>
    <inquireDialog v-if="isSalesOrder" :isVisible="isSalesOrder" :obj="salesOrderObj" :title="'销售订单'" :isChoose="false"
      :searchValue="salesOrderObj.params.search" @onIsVisible="salesOrderVisible" @onApplied="salesOrderApplied">
    </inquireDialog>
    <inquireDialog v-if="isSampleSubmission" :isVisible="isSampleSubmission" :obj="SampleSubmissionObj" :title="'送样单'"
      :isChoose="false" :searchValue="SampleSubmissionObj.params.search" @onIsVisible="SampleSubmissionVisible"
      @onApplied="salesOrderApplied">
    </inquireDialog>
    <el-dialog v-if="isInventory" :modal="false" :visible.sync="isInventory" width="50%" :show-close="false" v-dialogDrags
      :close-on-click-modal="false" :fullscreen="isInventoryFullscreen">
      <div slot="title" style="display: flex;justify-content: space-between; align-items: center;;">
        <div class="title">
          <span style="padding: 10px;">库存</span>
        </div>
        <div>
          <i v-if="isInventoryFullscreen" class="iconfont icon-zuidahua " style=""
            @click.stop="onFullscreen('isInventoryFullscreen')"></i>
          <i v-else class="iconfont icon-chuangkouzuidahua" @click.stop="onFullscreen('isInventoryFullscreen')"></i>
          <i class="el-icon-close" @click="isInventory = false"></i>
        </div>
      </div>
      <div style="margin-bottom:10px;display:flex;justify-content:center;">
        <el-input type="text" style="width: 70%;" v-model="inventoryObj.search" @keyup.enter.native="onSearchInventory"
          size="mini" placeholder="请输入内容" clearable>
          <el-button slot="append" type="primary" icon="el-icon-search" @click="onSearchInventory">搜索</el-button>
        </el-input>
      </div>
      <el-table v-loading="isInventoryLoading" class="inventory" :data="tableList[currentPage - 1]" border
        style="border-color: rgb(220, 223, 230);" :cell-style="{
          color: 'rgba(95, 93, 93, 1)', borderColor: 'rgb(220, 223, 230)', backgroundColor: 'rgba(255,255,255,0)',
          textAlign: 'center'
        }" max-height="500px"
        :header-cell-style="{ color: 'rgba(151, 161, 163, 1)', height: '15px', borderColor: 'rgb(220, 223, 230)', backgroundColor: 'rgba(244,246,250,1)', textAlign: 'center' }">
        <el-table-column label="序号" type="index" fixed="left" width="50px"></el-table-column>
        <el-table-column label="品目编码" fixed="left" prop="item_code">
        </el-table-column>
        <el-table-column label="品目名称" prop="item_name">
        </el-table-column>
        <el-table-column label="规格" prop="specifications">
        </el-table-column>
        <el-table-column label="当前库存" prop="quantity" width="120px">
        </el-table-column>
        <el-table-column label="占用数量" prop="temporary_use_quantity" width="120px">
        </el-table-column>
        <el-table-column label="剩余数量" prop="rest_amount" width="120px">
        </el-table-column>
      </el-table>
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :page-sizes="[10, 50, 100, 200]" :page-size="pageSize" :current-page="currentPage"
        layout="sizes, prev, pager, next" :total="inventoryList.length">
      </el-pagination>
      <span slot="footer" class="dialog-footer">
        <el-button @click="isInventory = false" size="mini">取 消</el-button>
        <el-button type="primary" @click="isInventory = false" size="mini">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { debounce } from "@/utils/AntiShake";
import Preview from "@/components/OA/components/jsxPreview.vue";
import { EventBus } from '@/components/ProcessFlow/CRM/Forms/components/eventBus';
import SettingForm from "@/components/ProcessFlow/CRM/Forms/components/SettingForm.vue";
import inquireDialog from "@/components/ProcessFlow/CRM/Forms/components/Dialog.vue"
import Decimal from 'decimal.js';
export default {
  components: {
    SettingForm, Preview, inquireDialog,
  },
  props: ["isSaveAddOutboundOrder", "addOrderForm", "addOrdersCustomer", "formTitle", "readonly", "outboundOrderId"],
  data() {
    return {
      orderArr: [],
      isSettingLoading: false,
      isAddAddress: false,
      isFullscreen: false,
      dialogForm: null,
      dialogFromObj: null,
      specialDialog: "客户",
      ordersObj: {},
      settingForm: {
        form_root: '出库申请',
        classification: '出库申请',
        control_setting: [{
          default_value: null,
          options: [
            {
              label: null,
              value: null,
            },
          ],
          type: '文本输入框',
          model_class: null,
          url: null,
          model_class_field: null,
          model_class_value: null,
          is_numeric: false,
          particulars: [{
            type: null,
            url: null,
            form_data: null,
            table_data: null,
            front_label: null,
            front_value: null,
            end_label: null,
            end_value: null,
            relevance: null,
            field: null,
            acquire: null,
            is_edit: true,
            is_show: true,
            label: null,
            placeholder: null,
          }],
          permissions: {
            edit: true,
            show: true,
            update: true,
            require: false
          },
          visible_rule: {
            role_limit: []
          },
          basic_settings: {
            show_name: "field",
            field: "field",
            fields: "field",
            placeholder: "field"
          },
          numeric_settings: {
            max_digits: null,
            decimal_places: null
          },
        }]
      },

      // 特价开关
      isSpecial: false,
      // 特价名称
      specialValue: "",
      // 订单的产品当前index
      specialsIndex: null,
      specialsColumn: null,
      specialsRow: null,
      specialsItem: null,
      // 特价Dialog参数
      specialDialogObj: {
        searchField: "search",
        url: "crm/order_special_offer/",
        data: {
        },
      },

      is_unit_price: false,


      /* 
     带出订单
      */
      //订单开关
      isSalesOrder: false,
      // 订单参数
      salesOrderObj: {
        searchField: 'search',
        url: "crm/order_relationship_table/",
        params: {
          per_page: 10,
          page: 1,
          is_approve: 1,
          is_invalid: 0,
          contract_order_type: 1,
          search: "",
          customer: null,
        },
      },

      /* 
    带出送样单
     */
      // 送样单开关
      isSampleSubmission: false,
      // 送样单参数
      SampleSubmissionObj: {
        searchField: 'search',
        url: "crm/order_relationship_table/",
        params: {
          per_page: 10,
          page: 1,
          customer: null,
          search: "",
          is_approve: 1,
          is_invalid: 0,
          contract_order_type: 4,
        },
      },

      /* 
    查看库存
     */
      //库存数据
      tableList: [],
      //库存条数
      pageSize: 10,
      //库存页码
      currentPage: 1,
      //库存是否全屏
      isInventoryFullscreen: false,
      //库存搜索参数
      inventoryObj: {
        list: true,
        search: "",
      },
      //库存表格数据
      inventoryList: null,
      //是否显示库存
      isInventory: false,
      //库存是否加载
      isInventoryLoading: false,
      //带出库存参数
      salesOutInventoryList: [],

    }
  },
  methods: {

    // 控制表单背景颜色
    getBackgroundColor() {
      // console.log(this.ordersObj.isSpecialPaymentDays);
      if (this.ordersObj.isSpecialPaymentDays || this.ordersObj.is_tax == false || this.is_unit_price) {
        if (document.querySelectorAll(".card_jsxPreview").length == 0) {
          if (document.querySelectorAll(".dialog_jsxPreview").length == 0) {
            document.querySelectorAll(
              ".drawer_jsxPreview"
            )[0].children[0].children[0].children[1].style.backgroundColor =
              "#ff00000d";
          } else {
            document.querySelectorAll(
              ".dialog_jsxPreview"
            )[0].children[0].children[1].style.backgroundColor = "#ff00000d";
          }
        } else {
          document.querySelectorAll(
            ".card_jsxPreview"
          )[0].style.backgroundColor = "#ff00000d";
        }
      } else {
        if (document.querySelectorAll(".card_jsxPreview").length == 0) {
          if (document.querySelectorAll(".dialog_jsxPreview").length == 0) {
            document.querySelectorAll(
              ".drawer_jsxPreview"
            )[0].children[0].children[0].children[1].style.backgroundColor =
              "#fff";
          } else {
            document.querySelectorAll(
              ".dialog_jsxPreview"
            )[0].children[0].children[1].style.backgroundColor = "#fff";
          }
        } else {
          document.querySelectorAll(
            ".card_jsxPreview"
          )[0].style.backgroundColor = "#fff";
        }
      }
    },
    // 判断单价
    onUnitPrice: debounce(function (index) {

      if (this.ordersObj.products[index].special_offer_obj !== null) {
        if (this.ordersObj.products[index].special_offer_obj.price != undefined) {
          if (Number(this.ordersObj.products[index].unit_price) >= Number(this.ordersObj.products[index].special_offer_obj.price)) {
          } else {
            if (this.ordersObj.products[index].unit_price != null && this.ordersObj.products[index].unit_price != "") {
              // this.is_unit_price = true
            } else {
              this.ordersObj.products[index].unit_price = "";
              this.$message.error("价格不允许空");
            }
          }
        } else {
          if (this.ordersObj.products[index].unit_price >= this.ordersObj.products[index].special_offer_obj.min_price && this.ordersObj.products[index].unit_price <= this.ordersObj.products[index].special_offer_obj.max_price) {
          } else {
            this.ordersObj.products[index].unit_price = "";
            this.$message.error("价格不允许");
          }
        }
        // console.log(index);
        // this.getBackgroundColor()
      }
    }, 500),
    onOrdersSpecialNumbers(index, column, row, item) {
      console.log(index, column.field, row, item);
      console.log(this.ordersObj[item.basic_settings.field][index][column.field]);
      if (this.ordersObj[item.basic_settings.field][index][column.field] === null || this.ordersObj[item.basic_settings.field][index][column.field] === '' || this.ordersObj[item.basic_settings.field][index][column.field] === undefined) {
        // return console.log('退出');
      } else {
        // console.log("执行");
        // this.onUnitPrice(index)
      }
    },
    specialVisible(val) {
      // console.log(val);
      this.isSpecial = val
    },
    specialApplied(val) {
      EventBus.$emit('onIsEditForm', false);
      let obj = {
        approve_number: val.approve_number,
        field0: val.field0,
        field1: val.field1,
        field2: val.field2,
        field3: val.field3,
        field4: val.field4,
        field6: val.field6,
        field7: val.field7,
        id: val.id,
        is_using: val.is_using,
        item_code: val.item_code,
        item_name: val.item_name,
        max_price: val.max_price,
        min_price: val.min_price,
        price: val.price,
        priority: val.priority,
        product: val.product,
        special_offer_creator: val.special_offer_creator,
        special_offer_name: val.special_offer_name,
        specifications: val.specifications,
        tag: val.tag,
        unit: val.unit,
      }
      this.ordersObj[this.specialsItem.basic_settings.field][this.specialsIndex].special_offer_obj = obj
      this.ordersObj[this.specialsItem.basic_settings.field][this.specialsIndex].special_offer_id = val.id
      this.ordersObj[this.specialsItem.basic_settings.field][this.specialsIndex].unit_price = val.price * 1
      this.ordersObj[this.specialsItem.basic_settings.field][this.specialsIndex].special_offer_price = val.price * 1
    },
    onOrdersSpecials(index, column, row, item) {
      // console.log(index, column, row);
      this.specialsIndex = index
      this.specialsColumn = column
      this.specialsRow = row
      this.specialsItem = item
      this.specialDialogObj.data = {
        customer_id: this.ordersObj.customer_id,
        product_id: this.ordersObj[item.basic_settings.field][index].product_id,
        quantity: this.ordersObj[item.basic_settings.field][index].quantity,
        user_id: localStorage.getItem('user_id')
      }
      this.isSpecial = true
    },
    async getDeliverAddr(id) {
      if (id === null || id === '') {
        return
      }
      let res = await this.$http({
        method: "GET",
        url: "crm/customer_receiver_information/",
        params: {
          customer: id,
        },
      });
      this.settingForm.control_setting.forEach(item => {
        if (item.basic_settings.field === 'deliver_addr') {
          item.options = res.data
          res.data.forEach(items => {
            if (items.child) {
              this.ordersObj[item.basic_settings.field] = items.label
            }
          })
        }
      })
    },
    async getConsigneeName(id) {
      if (id === null || id === '') {
        return
      }
      let res = await this.$http({
        method: "GET",
        url: "crm/contact_bulk/",
        params: {
          user: localStorage.getItem("user_id"),
          customer_id: id,
        },
      });
      let arr = []
      let field = null
      // let phone = null
      res.data.table_body_data.forEach(items => {
        let obj = {
          label: items.contact_name,
          value: items.id,
          consignee_phone: items.consignee_phone,
          is_default: items.is_default,
        }
        arr.push(obj)
        if (items.is_default) {
          field = items.contact_name
          // phone = items.consignee_phone
        }
      })
      this.settingForm.control_setting.forEach(item => {
        if (item.basic_settings.field === 'consignee_name') {
          item.options = arr
          this.ordersObj[item.basic_settings.field] = field
        }
      })
    },
    async getPaymentDays(id) {
      let res = await this.$http.get("crm/customer_payment_days_verify/", {
        params: { customer_id: id },
      });
      // console.log("666666");
      this.ordersObj.payment_days = res.data.payment_days
      this.ordersObj.credit_limit = res.data.credit_limit
      this.ordersObj.credit_rule = res.data.credit_rule
      this.ordersObj.payment_method = res.data.credit_rule_display
    },
    onOrdersPreview(obj, val, flag) {
      // console.log(obj, val, flag);
      this.dialogFromObj = JSON.parse(JSON.stringify(val));
      this.dialogForm = JSON.parse(JSON.stringify(obj));
      this.isAddAddress = flag;
    },
    getFormList(val) {
      if (val.arr.length == 0) {
        console.log(val);
        if (val.data.status == 200) {
          // this.deliverMethodChange();
          this.$message.success("提交成功，审批流程已发起");
        } else {
          this.$message.error(val.data.data);
        }
      } else {
        if (val.arr[0].status == 200) {
          // this.deliverMethodChange();
          this.$message.success("提交成功，审批流程已发起");
        } else {
          this.$message.error(val.arr[0].data);
        }
      }
      this.isAddAddress = false;
      this.dialogFromObj = null;
    },
    onFullscreen(val) {
      this[val] = !this[val]
    },
    onOrdersModificationTable(field, index, val) {
      // console.log(index, val);
      this.ordersObj[field][index].unit_price = val

    },
    onProcurement(val) {
      console.log(val);
      // this.ordersObj = val
    },
    onAppliedForm(val) {
      this.ordersObj = val
    },
    funDate(aa) {
      let date1 = new Date();
      let time1 =
        date1.getFullYear() +
        "-" +
        (date1.getMonth() + 1) +
        "-" +
        date1.getDate(); //time1表示当前时间
      let date2 = new Date(date1);
      date2.setDate(date1.getDate() + aa);
      let year = date2.getFullYear();
      let month = date2.getMonth() + 1;
      let day = date2.getDate();
      if (month < 10) {
        month = "0" + month;
      }
      if (day < 10) {
        day = "0" + day;
      }
      let time2 = year + "-" + month + "-" + day;
      return time2;
    },
    async getSettingForm() {
      const res = await this.$http({
        method: "GET",
        url: "rbac/form_control_setting/",
        params: {
          form_root: '出库申请',
          classification: '出库申请',
        }
      })
      if (res.data[0] != null) {
        let arr = JSON.parse(JSON.stringify(res.data[0]))
        let today = new Date();
        let date = today.getFullYear() + "-" + (today.getMonth() + 1 < 10 ? "0" + (today.getMonth() + 1) : today.getMonth() + 1) + "-" + (today.getDate() < 10 ? "0" + today.getDate() : today.getDate());
        arr.control_setting.forEach((item, i) => {
          if (item.basic_settings.field == 'outbound_time') {
            item.default_value = date
          }
          if (item.basic_settings.field == 'order_life_date') {
            item.default_value = this.funDate(60);
          }
          if (item.basic_settings.field == 'is_tax') {
            item.default_value = true
          }
          if (this.readonly) {
            if (item.type == '下拉选择框(单选)' || item.type == '下拉选择框(多选)') {
              this.getSelect(item)
            }
          }
        })
        this.settingForm = arr
        this.isSettingLoading = true
      }
    },
    async getSelect(val) {
      if (val.url != null && val.url != '') {
        const res = await this.$http({
          method: "GET",
          url: val.url,
        })
        if (val.url == 'crm/customer_payment_days_verify/?customer_id=1') {
          val.options = res.data.credit_rule_choice
        } else {
          val.options = res.data
        }
      }
    },
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "合计";
          return;
        }
        const values = data.map((item) => Number(item[column.property]));
        if (column.property === "total_actual_amount") {
          const sum = values.reduce((prev, curr) => {

            const value = Number(curr);
            if (!isNaN(value)) {
              let s1 = prev.toString();
              let s2 = value.toString();
              let decimal1 = new Decimal(s1)
              let decimal2 = new Decimal(s2)
              // let decimal3 = decimal1.times(decimal2)
              return decimal1.plus(decimal2);
            } else {
              return prev;
            }
          }, 0);
          sums[index] = sum.toString().replace(/(\d)(?=(\d{3})+\.)/g, "$1,");
        } else {
          sums[index] = '';
        }
      });
      return sums;
    },
    onSalesOrder() {
      this.salesOrderObj.params.search = this.ordersObj.customerValue
      this.isSalesOrder = true;
      this.settingForm.control_setting.forEach(item => {
        if (item.basic_settings.field === 'deliver_method') {
          item.options = [
            {
              "label": "交货到客户公司",
              "value": "交货到客户公司"
            },
            {
              "label": "交货到客户指定点",
              "value": "交货到客户指定点"
            },
            {
              "label": "客户自提",
              "value": "客户自提"
            }
          ]
        }
        if (item.type == '下拉选择框(单选)' || item.type == '下拉选择框(多选)') {
          this.getSelect(item)
        }
      })
    },
    salesOrderVisible(val) {
      this.isSalesOrder = val;
    },
    onSampleSubmission() {
      this.SampleSubmissionObj.params.search = this.ordersObj.customerValue
      this.isSampleSubmission = true;
      this.settingForm.control_setting.forEach(item => {
        if (item.basic_settings.field === 'deliver_method') {
          item.options = [
            {
              "label": "顺丰",
              "value": "顺丰"
            },
            {
              "label": "中通",
              "value": "中通"
            },
            {
              "label": "韵达",
              "value": "韵达"
            },
            {
              "label": "圆通",
              "value": "圆通"
            },
            {
              "label": "申通",
              "value": "申通"
            },
            {
              "label": "极兔",
              "value": "极兔"
            },
            {
              "label": "德邦",
              "value": "德邦"
            },
            {
              "label": "其他",
              "value": "其他"
            }
          ]
        }
        if (item.type == '下拉选择框(单选)' || item.type == '下拉选择框(多选)') {
          this.getSelect(item)
        }
      })
    },
    SampleSubmissionVisible(val) {
      this.isSampleSubmission = val;
    },
    allElementsEqual(arr) {
      if (arr.length === 0) return true; // 空数组返回 true
      return arr.every(val => val === arr[0]);
    },
    async salesOrderApplied(val) {
      try {
        this.orderArr = [];
        const promises = val.map(async (item) => {
          return await this.getOrderApplied(item.id);
        });
        const results = await Promise.all(promises);
        this.orderArr = results.filter(item => item !== null);
        // console.log(this.orderArr)
        let arr1 = [], arr2 = [], arr3 = [];
        this.orderArr.forEach(item => {
          arr1.push(item.payment_days)
          arr2.push(item.isSpecialPaymentDays)
          arr3.push(item.special_payment_days)
        })
        // console.log(arr1, arr2, arr3);
        // console.log(this.allElementsEqual(arr1), this.allElementsEqual(arr2), this.allElementsEqual(arr3))
        // return
        if (this.allElementsEqual(arr1) && this.allElementsEqual(arr2) && this.allElementsEqual(arr3)) {
          let arr = []
          this.orderArr.forEach(item => {
            item.products.forEach(items => {
              items.sales_order_id = item.order_id
              items.order_number = item.order_number || item.send_sample_number
              arr.push(items)
            })
          })
          for (const key in this.orderArr[0]) {
            if (key != 'products') {
              this.ordersObj[key] = this.orderArr[0][key]
            }
          }
          this.ordersObj.products = arr
          this.ordersObj.customer_obj = {
            label: this.ordersObj.customerValue,
            value: this.ordersObj.customer_id,
          }
          this.ordersObj.warehouse_obj = {
            label: this.ordersObj.warehouseValue,
            value: this.ordersObj.ware_house_id,
          }
          this.ordersObj.salesman_id = this.orderArr[0].creator_id
          this.ordersObj.salesman_name = this.orderArr[0].creator_name
          delete this.ordersObj.creator_id
          delete this.ordersObj.creator_name
          delete this.ordersObj.send_sample_number
          delete this.ordersObj.order_id
          delete this.ordersObj.content_data
          // console.log(this.ordersObj);
          if (this.ordersObj.deliver_method === '交货到客户指定点' || this.ordersObj.deliver_method === '交货到客户公司') {
            this.getDeliverAddr(this.ordersObj.customer_id)
            this.getConsigneeName(this.ordersObj.customer_id)
          }
        } else {
          this.orderArr = []
          // console.log(this.orderArr, this.ordersObj, this.$refs);
          // this.$refs.ProcurementOrders.resetFields();
          return this.$message.error('账期不同请从新选择')
        }
      } catch (error) {
        console.error('Error in salesOrderApplied:', error);
      }
    },
    async getOrderApplied(id) {
      try {
        const res = await this.$http({
          method: "GET",
          url: `crm/order_relationship_table/${id}`,
        });
        if (res.status === 200) {
          let val = null;
          res.data.oa_form.content.forEach(item => {
            if (item.control_title === "表单套件") {
              val = JSON.parse(item.value);
            }
          });
          if (val) {
            val.order_id = id;
          }
          return val;
        }
        return null;
      } catch (error) {
        console.error('Error fetching order applied:', error);
        return null;
      }
    },
    // 查看库存
    onInventory() {
      this.postInventory(this.inventoryObj)
      this.isInventory = true
    },
    // 查库存post
    async postInventory(val) {
      this.isInventoryLoading = true
      let obj = JSON.parse(JSON.stringify(val))
      if (val.search != "" && val.search != null) {
        obj.item_code_list = [val.search]
        delete obj.search
      } else {
        delete obj.search
        delete obj.item_code_list
      }
      const res = await this.$http({
        url: "crm/sales_outbound_table/query_ecount_for_output_quantity_enough/",
        method: "POST",
        data: obj
      })
      if (res.status == 200) {
        this.inventoryList = res.data
        this.tableList = []
        for (var i = 0; i < this.inventoryList.length; i += this.pageSize) {
          this.tableList.push(this.inventoryList.slice(i, i + this.pageSize));
        }
        this.isInventoryLoading = false
      } else {
        this.inventoryList = null
        this.$message.error(res.data)
        this.isInventoryLoading = false
      }
      // this.isInventoryLoading = false
    },
    // 搜索库存
    onSearchInventory() {
      this.inventoryObj.search = this.inventoryObj.search.replace(/^\s+|\s+$/g, '')
      this.currentPage = 1
      this.postInventory(this.inventoryObj)
    },
    //带出库存
    async onSalesOutInventory() {
      let arr = []
      this.ordersObj.products.forEach((item, i) => {
        if (item.product_code != null && item.product_code != '') {
          arr.push(item.product_code)
        }
      })
      if (arr.length > 0) {
        console.log(arr)
        let obj = {
          list: true,
          item_code_list: arr
        }
        const res = await this.$http({
          method: "POST",
          url: "crm/sales_outbound_table/query_ecount_for_output_quantity_enough/",
          data: obj
        })
        if (res.status == 200) {
          // console.log(res.data);
          this.salesOutInventoryList = res.data
          let field = null;
          this.settingForm.control_setting.forEach(item => {
            if (item.type === '表格') {
              // console.log(item.basic_settings.field)
              field = item.basic_settings.field
              item.particulars.forEach(items => {
                // console.log(items.field)
                if (items.field === 'current_inventory') {
                  items.is_show = true
                }
                if (items.field === 'rest_amount') {
                  items.is_show = true
                }
                if (items.field === 'temporary_use_quantity') {
                  items.is_show = true
                }
              })
            }
          })
          this.ordersObj[field].forEach((item, i) => {
            this.salesOutInventoryList.forEach(items => {
              if (item.product_code == items.item_code) {
                this.ordersObj[field][i].current_inventory = items.quantity
                this.ordersObj[field][i].rest_amount = items.rest_amount
                this.ordersObj[field][i].temporary_use_quantity = items.temporary_use_quantity
              }
            })
          })
        } else {
          this.$message.error(res.data)
        }

      } else {
        this.$message.error('请添加产品')
      }
    },

  },
  watch: {
    isAddAddress: {
      handler(val) {
        if (!val) {
          this.dialogForm = null
          this.dialogFromObj = null
        }
      },
    },
    settingForm: {
      handler(val) {
        EventBus.$emit('onIsEditForm', true);
      }
    },
    addOrderForm: {
      handler(val) {
        if (this.formTitle == "出库申请(亿看)") {
          if (val !== undefined) {
            for (const key in val) {
              this.ordersObj[key] = val[key]
            }
          }
        }
      },
      deep: true,
      immediate: true,
    },
    ordersObj: {
      handler(val) {
        if (this.isSaveAddOutboundOrder == false && this.outboundOrderId == undefined) {
          this.$emit("onAddOutboundOrder", this.ordersObj);
        }
      },
      deep: true,
    },
    "ordersObj.is_tax": {
      handler(val) {
        this.getBackgroundColor();
      }
    },
    "ordersObj.products": {
      handler(val) {
        const isValid = (value) => value != null && value !== '';
        if (this.$store.state.isphone) {
          // console.log(val);
          val.forEach(item => {
            if (isValid(item.quantity) && isValid(item.unit_price)) {
              let s1 = item.unit_price.toString();
              let s2 = item.quantity.toString();
              let decimal1 = new Decimal(s1)
              let decimal2 = new Decimal(s2)
              let decimal3 = decimal1.times(decimal2)
              item.total_actual_amount = decimal3 * 1
            }
          })
        } else {
          if (this.$refs.ProcurementOrders.$refs.table_ref == undefined) {
            return
          }
          const data = this.$refs.ProcurementOrders.$refs.table_ref[0].data;
          const columns = this.$refs.ProcurementOrders.$refs.table_ref[0].columns;
          data.forEach(row => {
            if (isValid(row.unit_price) && isValid(row.quantity)) {
              let s1 = row.unit_price.toString();
              let s2 = row.quantity.toString();
              let decimal1 = new Decimal(s1)
              let decimal2 = new Decimal(s2)
              let decimal3 = decimal1.times(decimal2)
              row.total_actual_amount = decimal3 * 1
            }
          });
          let arr = this.getSummaries({
            columns: columns,
            data: data
          })
          console.log(arr);
          const footerWrapper = this.$refs.ProcurementOrders.$refs.table_ref[0].$refs.footerWrapper.querySelector('.has-gutter');
          const cells = footerWrapper.querySelectorAll('.cell');
          cells.forEach((item, i) => {
            item.innerText = arr[i]
          })
        }
        this.is_unit_price = false;
        val.forEach((item, i) => {
          if (item.unit_price != null && item.special_offer_obj != null) {
            if (item.unit_price * 1 < item.special_offer_obj.price * 1) {
              this.is_unit_price = true;
            }
          }
        });
        this.getBackgroundColor();
      },
      deep: true,
    },
    "ordersObj.payment_method": {
      handler(val) {
        EventBus.$emit('onIsEditForm', false);
        this.settingForm.control_setting.forEach(item => {
          if (item.basic_settings.field === 'AdvancePayment_ratio') {
            if (val === '约定账期') {
              item.permissions.show = true
            } else {
              item.permissions.show = false
            }
          }
          if (item.basic_settings.field === 'isSpecialPaymentDays') {
            if (val === '约定账期') {
              item.permissions.edit = true
            } else {
              item.permissions.edit = false
            }
          }
        });
      },
      deep: true,
      immediate: true
    },
    "ordersObj.deliver_method": {
      handler(val) {
        EventBus.$emit('onIsEditForm', false);
        this.settingForm.control_setting.forEach(item => {
          if (item.basic_settings.field === 'deliver_addr') {
            if (val === '交货到客户指定点' || val === '交货到客户公司') {
              item.permissions.show = true
              if (!this.readonly) {
                this.getDeliverAddr(this.ordersObj.customer_id)
              }
            } else {
              item.permissions.show = false
            }
          }
          if (item.basic_settings.field === 'consignee_name') {
            if (val === '交货到客户指定点' || val === '交货到客户公司') {
              item.permissions.show = true
              if (!this.readonly) {
                this.getConsigneeName(this.ordersObj.customer_id)
              }
            } else {
              item.permissions.show = false
            }
          }
        });
      },
      deep: true,
      immediate: true
    },
    "ordersObj.isSpecialPaymentDays": {
      handler(val) {
        EventBus.$emit('onIsEditForm', false);
        this.settingForm.control_setting.forEach(item => {
          if (item.basic_settings.field === 'special_payment_days') {
            if (val) {
              item.permissions.show = true
            } else {
              item.permissions.show = false
              this.ordersObj.special_payment_days = null
            }
          }
        })
        this.getBackgroundColor();
      }
    },
    "ordersObj.customer_id": {
      handler(val) {
        if (val != '' && val != null) {
          this.getPaymentDays(val)
          if (this.ordersObj.customer_obj.label != null) {
            if (!this.readonly) {
              this.ordersObj.customerValue = this.ordersObj.customer_obj.label
            }
          }
        }
      },
      deep: true,
    },
    "ordersObj.ware_house_id": {
      handler(val) {
        if (val != '' && val != null) {
          if (this.ordersObj.warehouse_obj.label != null) {
            if (!this.readonly) {
              this.ordersObj.warehouseValue = this.ordersObj.warehouse_obj.label
            }
          }
        }
      },
      deep: true,
    },
    "ordersObj.customerValue": {
      handler(val) {
        if (this.ordersObj.customer_obj.label == null) {
          this.ordersObj.customer_obj = {
            label: val,
            value: this.ordersObj.customer_id,
          }
        }
      },
      deep: true,
    },
    "ordersObj.warehouseValue": {
      handler(val) {
        if (this.ordersObj.warehouse_obj.label == null) {
          this.ordersObj.warehouse_obj = {
            label: val,
            value: this.ordersObj.ware_house_id,
          }
        }
      },
      deep: true,
    },
    addOrdersCustomer: {
      handler(val) {
        const isValid = (value) => value != null && value !== '';
        if (isValid(val) && !this.readonly) {
          // console.log(val, '66666666666666666')
          this.ordersObj.customer_obj = {
            label: val.customer.company_name,
            value: val.customer.id,
          }
          this.ordersObj.customerValue = val.customer.company_name
          this.ordersObj.customer_id = val.customer.id
          this.ordersObj.salesman_id = val.salesman.value
          this.ordersObj.salesman_name = val.salesman.label
        }
      }
    }
  },
  mounted() {
  },
  created() {
    this.getSettingForm()
  },
}
</script>
<style lang="less" scoped>
/deep/.el-dialog__header {
  background-color: rgb(89, 87, 87);
  color: #fff;
  padding: 0 !important;

  .icon-zuidahua {
    font-size: 16px;
    cursor: pointer;
    padding: 10px;

    &:hover {
      color: #898989;
    }
  }

  .icon-chuangkouzuidahua {
    font-size: 16px;
    cursor: pointer;
    padding: 10px;

    &:hover {
      background-color: #898989;
    }
  }

  .el-icon-close {
    font-size: 16px;
    cursor: pointer;
    padding: 10px;

    &:hover {
      background-color: #898989;
    }
  }
}
</style>